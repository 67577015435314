<template>
  <div class="elv-report-reconciliation-container">
    <Header
      ref="reconciliationHeaderRef"
      type="reconciliation"
      :title="t('report.reconciliation')"
      :updatedOn="''"
      :showRegenerate="false"
      :showRegenerateButton="false"
    />
    <div class="elv-report-reconciliation-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup lang="ts">
import Header from '../components/Header.vue'

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.elv-report-reconciliation-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-report-reconciliation-content {
    margin-top: 16px;
    padding-top: 12px;
    flex: 1;
    min-height: 0;
    position: relative;
    box-sizing: border-box;
    border-top: 1px solid #dde1e6;
  }
}
</style>
